import { type FunctionComponent } from 'react';
import { generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { type PromoBannerVariationOptions } from '../../../helpers/recommendations/recommendations.helper';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { DoorMarker } from '../promo-helpers/door-marker.component';
import { defaultCouponExpirationString } from '../promo-helpers/promo-banner.helpers';

export const PromoBannerB: FunctionComponent<PromoBannerVariationOptions> = ({
	headline,
	subHeading,
	couponCode,
	expirationDate,
	action,
	image,
	imageOverlay
}) => {
	const backgroundUrl = generateCloudinaryUrl(image.id, {
		width: 950,
		height: undefined,
		gravity: 'custom',
		zoom: '0.9',
		crop: 'thumb'
	});
	const actionName = action?.name || '';
	return (
		<div
			className={`min-h4 pa3 ba b--theme-grey-light bg-theme-black-o30 cover relative-ns`}
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundUrl}) no-repeat center center`,
				boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.3)'
			}}>
			<div className="b f4 f3-ns theme-white">{headline}</div>
			<div className="f6 f5-ns lh-title mt2 theme-white">{subHeading}</div>
			{(couponCode || expirationDate) && (
				<div className="f7 f5-ns mt2 theme-white">
					<DoorMarker color="white" />
					<span className="nowrap">{defaultCouponExpirationString(couponCode, expirationDate)}</span>
				</div>
			)}
			<div className="dn db-ns f6 f5-ns mt5-ns theme-white underline-ns">{actionName}</div>
			{imageOverlay && (
				<CloudinaryImage
					publicID={imageOverlay.id}
					description="promo"
					className="absolute bottom-0 dn db-ns max-h-100 pa3 right-0"
					options={{ width: 100, height: undefined, crop: 'lfill', gravity: 'custom', preserveTransparency: true }}
					useDimensions={false}
				/>
			)}
			<div className="dn-ns">
				<div className="flex mt3">
					<div className="flex items-end w-50"></div>
					<div className="w-50">
						<div className="absolute bottom-0 f6 right-0 theme-white pa3">{actionName}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
