import { type FunctionComponent, useEffect, useReducer, useState } from 'react';
import { FACET_GROUP_FILTER_SHOW_SIZE, QUICKSHIP_FACET_DISPLAY_VALUE, RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { formatNumber, generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetGroupIsQuickShip,
	findSelectedFacet,
	getFacetDisplayValue,
	getFacetGroupDisplayName,
	getRangeFacetValues,
	getUnitAffixes,
	isRangeFacetGroup
} from '../../../helpers/search-helper/search-helper';
import {
	type UseFacetGroupResults,
	useFacetGroupResults,
	useSearchResults,
	type UseSearchResultsPayload
} from '../../../hooks/apollo/search/search.hooks';
import { type FacetGroup as FacetGroupType, type FacetResult, type FacetValueBase, type SelectedFacet } from '../../../types/search.types';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Popover } from '../../common-components/popover/popover.component';
import { DynamicTextInput, TextInput } from '../../inputs';
import { Checkbox } from '../../inputs/checkbox/checkbox.component';
import { QuickShip } from '../../quick-ship/quick-ship.component';
import {
	CheckIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	CloseIcon,
	HelpCircleIcon,
	InfoIcon,
	SearchIcon,
	TuneIcon
} from '../../svg/icons.component';
import { Range } from '../range/range.component';
import { SortByDropdown } from '../sort-by-dropdown/sort-by-dropdown.component';
import { facetListReducer, facetListReducerInitializer } from './facet-list-reducer';
import {
	checkbox,
	checkboxFill,
	checkboxSelected,
	expandIcon,
	facetListBackground,
	facetListGroup,
	facetListValues,
	facetSubHeading,
	facetTooltip,
	newLookFacetScrollableValues,
	newLookFacetsWrapper,
	newLookFacetsWrapperExpanded
} from './facet-list.css';

type SelectedFacetsSubHeadingProps = {
	group: FacetGroupType;
	facets: SelectedFacet[];
	isNewLookAndFeel: boolean;
	capitalizeValues: boolean;
};

const SelectedFacetsSubHeading: FunctionComponent<SelectedFacetsSubHeadingProps> = ({
	group,
	facets,
	isNewLookAndFeel,
	capitalizeValues
}) => {
	if (!facets.length) {
		return null;
	}
	const { isRangeGroup, selectedMinimum, selectedMaximum, isSelected } = getRangeFacetValues(group, facets);
	if (isRangeGroup && !isSelected) {
		return null;
	}
	const isQuickShip = facetGroupIsQuickShip(group);
	const capitalizeClass = capitalizeValues && !isRangeGroup ? 'ttc' : '';

	const numValuesToDisplay = isNewLookAndFeel ? 3 : Number.MAX_SAFE_INTEGER;
	const facetsToDisplay = isRangeGroup
		? [{ facetId: facets[0].facetId, value: `${selectedMinimum}${RANGE_FACET_VALUE_SEPARATOR}${selectedMaximum}` }]
		: facets.slice(0, numValuesToDisplay);
	const facetDisplayValues = facetsToDisplay.map((facet) => getFacetDisplayValue(facet, group));
	if (!isRangeGroup && facets.length > facetDisplayValues.length) {
		facetDisplayValues.push(`+${facets.length - facetDisplayValues.length}`);
	}

	return (
		<div className={isNewLookAndFeel ? 'fade-in mt2 fw3 w-100' : 'ph2 mt1 fw3 w-100'} data-testid="selectedFacetSubHeading">
			{!isRangeGroup && !isNewLookAndFeel && (
				<div className={`f7 lh-copy lh-title-l ${facetSubHeading}`}>{facets.length} Selected</div>
			)}
			{(!isQuickShip || isNewLookAndFeel) && (
				<div className={`f6 fw4 ${isNewLookAndFeel ? 'theme-primary-dark' : 'theme-primary'} ${capitalizeClass}`}>
					{facetDisplayValues.join(', ')}
				</div>
			)}
		</div>
	);
};

export type FacetsFilterProps = {
	group: FacetGroupType;
	value: string;
	onChange: (newValue: string) => void;
	isNewLookAndFeel: boolean;
};

const FacetsFilter: FunctionComponent<FacetsFilterProps> = ({ group, value, onChange, isNewLookAndFeel }) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
	const iconClass = 'f5 theme-grey ph2 input fade-in';
	return (
		<div className={`${isNewLookAndFeel ? 'mh2 mb2' : 'flex items-center hover-bg-theme-grey-lighter pa2'}`}>
			{isNewLookAndFeel ? (
				<DynamicTextInput
					value={value}
					size="DEFAULT"
					onChange={onInputChange}
					placeholder={`Find in ${group.name}`}
					ariaLabel={`Filter ${group.name} list`}
					className="f6"
					icon={value ? <CloseIcon className={iconClass} /> : <SearchIcon className={iconClass} />}
					padIcon={false}
					iconOnClick={() => (value ? onChange('') : undefined)}
					automationHook={group.name}
				/>
			) : (
				<TextInput
					value={value}
					onChange={onInputChange}
					placeholder={`Search by ${group.name}`}
					ariaLabel={`Filter ${group.name} list`}
					className="f6-l"
					automationHook={group.name}
				/>
			)}
		</div>
	);
};

export type FacetProps = {
	onFacetChange: (facet: any) => void;
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	facet: FacetResult;
	isNewLookAndFeel: boolean;
	index: number;
	showFilter: boolean;
};

const Facet: FunctionComponent<FacetProps> = ({ onFacetChange, group, selectedFacets, facet, isNewLookAndFeel, index, showFilter }) => {
	const isRangeGroup = isRangeFacetGroup(group);
	if (isRangeGroup) {
		const onRangeFacetChange = (lowRange: string, highRange: string) => {
			// Combine high and low values into one string using RANGE_FACET_VALUE_SEPARATOR for Search Hook methods
			const rangeValue = lowRange && highRange ? `${lowRange}${RANGE_FACET_VALUE_SEPARATOR}${highRange}` : '';
			onFacetChange({ facetId: facet.facetId, value: rangeValue });
		};
		const rangeValues = getRangeFacetValues(group, selectedFacets);
		const { unitPrefix, unitSuffix } = getUnitAffixes(group);
		return (
			<Range
				min={rangeValues.rangeMinimum}
				max={rangeValues.rangeMaximum}
				selectedMin={rangeValues.selectedMinimum}
				selectedMax={rangeValues.selectedMaximum}
				groupName={group.name}
				isSelected={rangeValues.isSelected}
				unitPrefix={unitPrefix}
				unitSuffix={unitSuffix}
				onRangeSubmit={onRangeFacetChange}
				isNewLookAndFeel={isNewLookAndFeel}
			/>
		);
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const label = isQuickShip ? (
		<QuickShip message={QUICKSHIP_FACET_DISPLAY_VALUE} messageClasses="" />
	) : (
		getFacetDisplayValue(facet, group)
	);
	const selectedFacet = selectedFacets?.find(
		(item) => item.facetId === facet.facetId && item.value.toLowerCase() === facet.value.toLowerCase()
	);
	const isSelected = Boolean(selectedFacet);
	const isAutoApplied = Boolean(selectedFacet?.autoApplied);
	const onChange = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onFacetChange(facet);
	};

	return (
		<div
			role="button"
			tabIndex={0}
			aria-disabled={isAutoApplied}
			className={`pointer overflow-hidden hover-bg-theme-grey-lighter ${
				isNewLookAndFeel
					? 'flex items-start ga2 ph2 input pv2-l'
					: `pa2 flex items-center ${index !== 0 || showFilter ? 'bn bt-l b--theme-grey-light' : ''}`
			}`}
			onClick={onChange}
			onKeyPress={handleKeys(['Enter', ' '], onChange)}
			data-testid="searchFacet">
			{isNewLookAndFeel ? (
				<>
					<div data-testid="facetCheckbox" className={`${checkbox} ${isSelected ? checkboxSelected : ''} flex-shrink-0`}>
						<div className={checkboxFill}>
							<CheckIcon className="db f5 theme-white" />
						</div>
					</div>
					<div className={`flex-grow-1 f5 lh-title ${isAutoApplied ? 'theme-grey' : ''}`}>{label}</div>
					<span className="flex-shrink-0 f6 lh-title theme-grey">({formatNumber(facet.count)})</span>
				</>
			) : (
				<div className="ml3 ml1-l f2 f6-l fw4">
					<Checkbox
						id={`${facet.value}-${facet.facetId}`}
						label={
							<div className="flex items-end content-center">
								<span className={`f5 f6-l ml2 ml0-l ws-normal ${isAutoApplied ? 'theme-grey' : ''}`}>{label}</span>
								<span className="f5 f6-l ml2 theme-grey lh-solid">({formatNumber(facet.count)})</span>
							</div>
						}
						name={facet.value}
						value={facet.value}
						isChecked={isSelected}
						automationHook={facet.value}
						tabIndex={-1}
						readOnly={true}
						disabled={isAutoApplied}
					/>
				</div>
			)}
		</div>
	);
};

export type FacetsProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	onFacetChange: (facet: FacetValueBase) => void;
	isNewLookAndFeel: boolean;
};

const Facets: FunctionComponent<FacetsProps> = ({ group, selectedFacets, onFacetChange, isNewLookAndFeel }) => {
	const [filterValue, setFilterValue] = useState('');
	const showFilter = group.facets.length > FACET_GROUP_FILTER_SHOW_SIZE;
	let facets = group.facets,
		onFilterChange;
	if (showFilter) {
		onFilterChange = (newValue: string) => setFilterValue(newValue);
		const cleanedFilterValue = filterValue.trim().toLowerCase();
		if (cleanedFilterValue) {
			facets = facets.filter((facet) => facet.value.toLowerCase().includes(cleanedFilterValue));
		}
	}

	const isRangeGroup = isRangeFacetGroup(group);
	const facetCountForScroll = isNewLookAndFeel ? 7 : showFilter ? 6 : 8;
	const showScrollbar = group.facets.length >= facetCountForScroll;
	const newLookScrollClasses = showScrollbar ? `overflow-y-auto-l ${newLookFacetScrollableValues}` : '';
	const listItemClasses = isNewLookAndFeel
		? `${newLookScrollClasses} pb2`
		: `bg-theme-white pt2 pt0-l bt b--theme-grey-light ${!isRangeGroup ? facetListValues : ''}`;

	return (
		<>
			{showFilter && isNewLookAndFeel && (
				<FacetsFilter isNewLookAndFeel={isNewLookAndFeel} group={group} value={filterValue} onChange={onFilterChange} />
			)}
			<div className={listItemClasses} data-automation="facet-range" data-testid={`${group.name} values`}>
				{showFilter && !isNewLookAndFeel && (
					<FacetsFilter isNewLookAndFeel={isNewLookAndFeel} group={group} value={filterValue} onChange={onFilterChange} />
				)}
				{facets.map((facet, index) => (
					<Facet
						key={`${facet.facetId}-${facet.value}`}
						facet={facet}
						group={group}
						index={index}
						isNewLookAndFeel={isNewLookAndFeel}
						onFacetChange={onFacetChange}
						showFilter={showFilter}
						selectedFacets={selectedFacets}
					/>
				))}
				{facets.length === 0 && (
					<div className="flex items-center pa2 overflow-hidden">
						<div className={isNewLookAndFeel ? 'f5 ml2 lh-title' : 'f5 f6-l ml2 ml0-l'}>
							{isNewLookAndFeel ? (
								<>No {filterValue ? 'matches found' : 'filters available'}.</>
							) : (
								<>No {filterValue ? 'filtered' : ''} values found.</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export type FacetGroupInfoProps = {
	description: string;
	term: string;
	isNewLookAndFeel: boolean;
};

const FacetGroupInfo: FunctionComponent<FacetGroupInfoProps> = ({ description, term, isNewLookAndFeel }) => {
	const [isOpen, setIsOpen] = useState(false);
	const helpTitle = `Get help with ${term}`;
	const toggleElement = isNewLookAndFeel ? (
		<span title={helpTitle}>
			<InfoIcon className="pointer db theme-grey" />
		</span>
	) : (
		<HelpCircleIcon className="pointer theme-grey-darker" />
	);
	const eventBlocker = (event: React.SyntheticEvent) => event.stopPropagation();
	return (
		// Stop propagation of clicks in the popover to prevent opening/closing the facet group.
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={`dn ${isNewLookAndFeel ? 'db-l pl1' : 'db-ns pl2-m pr2-l pt1-ns'}`}
			onClick={eventBlocker}
			onKeyDown={handleKeys(['Enter', ' '], eventBlocker)}>
			<Popover
				isVisible={isOpen}
				setIsVisible={setIsOpen}
				direction="bottom"
				toggleElement={toggleElement}
				parentElementClassName="relative flex">
				<PanelComponent
					headingContent={helpTitle}
					containerClassName={`${facetTooltip} pa3`}
					className="f7"
					headerClassName="f6 bg-theme-grey-lighter">
					<div style={{ minWidth: '20rem' }} dangerouslySetInnerHTML={{ __html: description }}></div>
				</PanelComponent>
			</Popover>
		</div>
	);
};

export type FacetGroupProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	isExpanded: boolean;
	onClick: (group: FacetGroupType) => void;
	useResults: () => UseSearchResultsPayload;
	useSearchFacetGroupResults: (facetGroupId: string) => UseFacetGroupResults;
};

const FacetGroup: FunctionComponent<FacetGroupProps> = ({
	group,
	selectedFacets = [],
	isExpanded,
	onClick,
	useResults,
	useSearchFacetGroupResults
}) => {
	const { query, addFacet, removeFacet, categoryId, isNewLookAndFeel, createGTMEvent, results } = useResults();
	const { loadFacetGroup, facetGroup: fullFacetGroup, called: loadFacetGroupCalled } = useSearchFacetGroupResults(group.id);
	const [renderFacets, setRenderFacets] = useState(isExpanded);
	const isSolr = results?.searchEngine === 'SOLR';

	// For isNewLookAndFeel, once we render a facet list we keep it in the DOM. This allows smooth expand and collapse.
	useEffect(() => {
		if (isExpanded && !renderFacets) {
			setRenderFacets(true);
		}
	}, [isExpanded, renderFacets]);

	// Fetch full facet group if expanded and more available.
	if (isExpanded && group.metadata.hasMoreFacets && !loadFacetGroupCalled) {
		loadFacetGroup();
	} else if (fullFacetGroup) {
		group = { ...fullFacetGroup, range: group.range, info: group.info, unitPrefix: group.unitPrefix, unitSuffix: group.unitSuffix };
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const isRangeGroup = isRangeFacetGroup(group);
	const facetGroupName = getFacetGroupDisplayName(group);

	const handleFacetChange = (facet: FacetValueBase) => {
		const selectedFacet = findSelectedFacet(selectedFacets, facet, { isQuickShip, isRangeGroup });
		const pageQuery = categoryId ? `c${categoryId}` : query;
		if (selectedFacet?.autoApplied) {
			return;
		}

		// Range facet behaves differently. Don't try to remove if selected.
		if (!facet.value || (selectedFacet && !isRangeGroup)) {
			removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: selectedFacet?.sourceFacetId });
		} else {
			addFacet({ id: facet.facetId, value: facet.value });
		}

		void trackSearchEvent(
			createGTMEvent(TrackedEvent.FACET_INTERACTION, {
				query: pageQuery,
				facet,
				group,
				applied: !selectedFacet
			})
		);
	};

	const handleFacetGroupPress = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick(group);
	};

	const chevronStyle = 'fw2 theme-grey mt1';
	const listItemClassName = isNewLookAndFeel
		? ''
		: `flex justify-between-l mt2 mt0-l bb b--theme-grey-light br0 pv2 pa0-l items-center ${facetListGroup}`;
	const wrapperClassName = isNewLookAndFeel ? 'bb b--theme-grey-light mh1 mh0-l' : '';
	const facetsWrapperClassName = isNewLookAndFeel ? `${newLookFacetsWrapper} ${isExpanded ? newLookFacetsWrapperExpanded : ''}` : '';
	const inertProp = isExpanded ? null : { inert: '' }; // Prevent hidden facets from receiving events, focus, etc.

	return (
		<div className={wrapperClassName}>
			<div className={listItemClassName} data-automation={generateDataSelector('facet', facetGroupName)}>
				<div className={`flex w-100 ${isNewLookAndFeel ? 'items-center' : 'w-90-ns pa3 pa2-l items-start'}`}>
					<div
						className={`w-100 pointer ${
							isNewLookAndFeel ? 'pv4 pv3-l ph2' : 'flex justify-start items-start justify-end-l flex-row-reverse-l'
						}`}
						role="button"
						tabIndex={0}
						onClick={handleFacetGroupPress}
						onKeyDown={handleKeys(['Enter', ' '], handleFacetGroupPress)}
						data-testid="searchFacetGroup">
						{isNewLookAndFeel ? (
							<>
								<div className="flex items-center gc1">
									<div className="lh-solid fw6">{facetGroupName}</div>
									{group.info && (
										<FacetGroupInfo
											isNewLookAndFeel={true}
											description={group.info.description}
											term={group.info.term}
										/>
									)}
									<ChevronUpIcon className={`ml-auto flex-shrink-0 ${expandIcon} ${isExpanded ? '' : 'rotate-180'}`} />
								</div>
								{!(isQuickShip && isExpanded) && (
									<SelectedFacetsSubHeading
										group={group}
										facets={selectedFacets}
										capitalizeValues={isSolr}
										isNewLookAndFeel={true}
									/>
								)}
							</>
						) : (
							<>
								<div className="w-100 self-end">
									<h2 className={`w-80 mv0 ml2 lh-title f5 truncate fw4 fw3-l`}>{facetGroupName}</h2>
									<SelectedFacetsSubHeading
										group={group}
										facets={selectedFacets}
										capitalizeValues={isSolr}
										isNewLookAndFeel={false}
									/>
								</div>
								{isExpanded ? (
									<div>
										<ChevronDownIcon className={`${chevronStyle} dn db-l`} />
										<ChevronUpIcon className={`${chevronStyle} db dn-l`} />
									</div>
								) : (
									<div>
										<ChevronRightIcon className={`${chevronStyle} dn db-l`} />
										<ChevronDownIcon className={`${chevronStyle} db dn-l`} />
									</div>
								)}
							</>
						)}
					</div>
				</div>
				{group.info && !isNewLookAndFeel && (
					<FacetGroupInfo isNewLookAndFeel={isNewLookAndFeel} description={group.info.description} term={group.info.term} />
				)}
			</div>
			<div className={facetsWrapperClassName} {...inertProp}>
				{(isExpanded || (isNewLookAndFeel && renderFacets)) && (
					<Facets
						group={group}
						selectedFacets={selectedFacets}
						onFacetChange={handleFacetChange}
						isNewLookAndFeel={isNewLookAndFeel}
					/>
				)}
			</div>
		</div>
	);
};

export type FacetListProps = {
	listContainerStyle?: React.CSSProperties;
	hideDesktopHeader?: boolean;
	scrollable?: boolean;
	includeSortBy?: boolean;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
};

export const FacetList: FunctionComponent<FacetListProps> = ({
	listContainerStyle,
	hideDesktopHeader = false,
	scrollable = false,
	includeSortBy = false,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults
}) => {
	const { results, loading: resultsLoading, isNewLookAndFeel, createGTMEvent, request } = useResults();
	const [state, dispatch] = useReducer(
		facetListReducer,
		{ searchResults: results, loading: resultsLoading },
		facetListReducerInitializer
	);
	useEffect(() => {
		if (resultsLoading) {
			dispatch({ type: 'loadingData' });
		} else {
			dispatch({ type: 'loadData', searchResults: results });
		}
	}, [resultsLoading, results]);

	const { facetGroups, selectedFacetGroups, groupExpansion, loading } = state;
	if (facetGroups.length === 0) {
		return null;
	}

	const someFacetsGroupsExpanded = Object.values(groupExpansion).some((value) => value);
	const toggleFacetGroupExpanded = (group: FacetGroupType) => {
		const event = groupExpansion[group.id] ? TrackedEvent.COLLAPSE_FACET_GROUP : TrackedEvent.EXPAND_FACET_GROUP;
		void trackSearchEvent(createGTMEvent(event, { group }));
		dispatch({ type: 'toggleGroupExpanded', id: group.id });
	};
	const toggleAllFacetGroupsExpanded = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch({ type: someFacetsGroupsExpanded ? 'collapseAllGroups' : 'expandAllGroups' });
	};

	const wrapperClassName = isNewLookAndFeel ? '' : `b--theme-grey-light pa0-ns ph2 ba-l ${facetListBackground}`;

	return (
		<section className={wrapperClassName}>
			{!hideDesktopHeader && (
				<div
					className={`${
						isNewLookAndFeel
							? 'bb b--theme-grey-light f4 fw6 flex-ns items-center justify-between pb2 pl2 pr1 lh-solid'
							: 'pa2 db-l'
					} dn fw3 ma0`}>
					{isNewLookAndFeel ? (
						<>
							<TuneIcon /> <span className="ml2 flex-grow-1">Filters</span>
						</>
					) : (
						<>Narrow Your Results</>
					)}
					<span
						onClick={toggleAllFacetGroupsExpanded}
						onKeyDown={handleKeys(['Enter', ' '], toggleAllFacetGroupsExpanded)}
						className={`${
							isNewLookAndFeel ? 'f5 fw4 input ph2 theme-primary-dark' : 'fw2 ttu pl3 theme-primary'
						} pointer underline-hover`}
						role="button"
						tabIndex={0}>
						{someFacetsGroupsExpanded ? 'Collapse' : 'Expand'} All
					</span>
				</div>
			)}
			<div className={`${isNewLookAndFeel ? '' : 'pb2 pb0-l'} ${scrollable ? 'overflow-y-auto' : ''}`} style={listContainerStyle}>
				{includeSortBy && <SortByDropdown useResults={useResults} />}
				{facetGroups.map((group) => {
					let selectedFacets = selectedFacetGroups.find((selectedGroup) => group.id === selectedGroup.id)?.facets;
					if (isNewLookAndFeel && loading) {
						// Use request facets while loading so selected values update as the user clicks.
						const groupFacetId = group.facets.length ? group.facets[0].facetId : null;
						selectedFacets = request.facetFilter
							.filter((rf) => rf.id === groupFacetId)
							.map((rf) => ({ facetId: rf.id, value: rf.value, autoApplied: false, sourceFacetId: null }));
					}
					return (
						<FacetGroup
							group={group}
							useResults={useResults}
							useSearchFacetGroupResults={useSearchFacetGroupResults}
							selectedFacets={selectedFacets}
							onClick={toggleFacetGroupExpanded}
							isExpanded={groupExpansion[group.id]}
							key={group.id}
						/>
					);
				})}
			</div>
		</section>
	);
};
