import { type FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { formatNumber, pluralize } from '../../../helpers/general-helper/general-helper';
import {
	useFacetGroupResults,
	type UseFacetGroupResults,
	useSearchResults,
	type UseSearchResultsPayload
} from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { StyledButton, TextButton } from '../../buttons';
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { Drawer } from '../../common-components/drawer/drawer.component';
import { Loading, PageLoading } from '../../common-components/loading/loading.component';
import { CloseIcon, TuneIcon } from '../../svg/icons.component';
import { FacetFilterList } from '../facet-filter-list/facet-filter-list.component';
import { FacetFilterListV2 } from '../facet-filter-list-v2/facet-filter-list-v2.component';
import { FacetList } from '../facet-list/facet-list.component';
import { PlpNavVariations } from '../search-header-v2/search-header-v2.component';
import { drawer, facetDrawerFilters, facetDrawerList } from './facet-drawer.css';

export type FacetDrawerProps = {
	onFacetDrawerClose: () => void;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
	searchHeaderV2FlagStatus?: string;
};

export type FacetDrawerHeaderProps = {
	useResults: () => UseSearchResultsPayload;
	onFacetDrawerClose: () => void;
	clearFacets: () => void;
	resultCount: string;
	loading: boolean;
	hasFilters: boolean;
	isNewLookAndFeel: boolean;
	searchHeaderV2FlagStatus?: string;
};

const FacetDrawerHeader: FunctionComponent<FacetDrawerHeaderProps> = ({
	useResults,
	onFacetDrawerClose,
	clearFacets,
	resultCount,
	loading,
	hasFilters,
	isNewLookAndFeel,
	searchHeaderV2FlagStatus
}) => {
	return isNewLookAndFeel ? (
		<div className="flex items-baseline pv2 pl4 pr6 bb b--theme-grey-light lh-solid">
			<TuneIcon className="f4 self-center" />
			<span className="ml2 pv3 f4 fw6">Filters</span>
			{hasFilters && (
				<TextButton onClick={clearFacets} underline={false} className="ml3 ph2 pv3 f5 fw4 lh-title theme-primary-dark fade-in">
					Clear All
				</TextButton>
			)}
			{loading && (
				<div className="ml3 f3 self-center">
					<Loading />
				</div>
			)}
		</div>
	) : searchHeaderV2FlagStatus === PlpNavVariations.control ? (
		<>
			<div className="pl3 pv3 bb b--theme-grey-light f3 fw7">
				<span className="f-inherit">Filter</span>
				<span className="f-inherit i ml5 ml6-ns">{`${resultCount} Results`}</span>
			</div>
			<div className="flex justify-between pa3">
				<StyledButton buttonStyle="SECONDARY" onClick={clearFacets}>
					Reset Filters
				</StyledButton>
				<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
					View Results
				</StyledButton>
			</div>
		</>
	) : (
		<div>
			<div className="pl3 pv3 bb b--theme-grey-light f3 fw7">
				<span className="f-inherit">Filter</span>
				<span className="f-inherit i ml5 ml6-ns">{`${resultCount} Results`}</span>
			</div>
			<div className="flex pa3">
				<div className="flex flex-grow-1 w-100 pr1">
					<StyledButton buttonStyle="SECONDARY" onClick={clearFacets}>
						Reset Filters
					</StyledButton>
				</div>
				<div className="flex flex-grow-1 w-100 pl1">
					<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
						View Results
					</StyledButton>
				</div>
			</div>

			<div className={`bg-theme-grey-lighter overflow-x-scroll overflow-y-hidden nowrap pv3`}>
				<FacetFilterListV2 useResults={useResults} />
			</div>
		</div>
	);
};

export const FacetDrawer: FunctionComponent<FacetDrawerProps> = ({
	onFacetDrawerClose,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults,
	searchHeaderV2FlagStatus
}) => {
	const searchResults = useResults();
	const { results, previousResults, isNewLookAndFeel, loading, clearFacets, request } = searchResults;
	const renderResults = results ?? previousResults;
	const listContainerStyle = isNewLookAndFeel ? {} : { height: 'calc(100vh - 210px)' };
	const resultCount = renderResults?.count ?? 0;
	const formattedCount = formatNumber(resultCount);
	const isSortInsideFacetDrawer = useFeature(FEATURE_FLAGS.SORT_INSIDE_FACET_DRAWER);
	const hasFilters =
		Boolean(renderResults?.selectedFacetGroups.length) || (isNewLookAndFeel && loading && request.facetFilter.length > 0);

	const customCloseButton = isNewLookAndFeel ? (
		<IconButton
			ariaLabel="close filters"
			onClick={onFacetDrawerClose}
			buttonStyle="ICON"
			size="ICON"
			className="bn absolute top-0 right-0 w-auto mt2 mr2 pv3">
			<CloseIcon className="f3 db" />
		</IconButton>
	) : (
		<div role="button" aria-label="close" className="absolute right-0 top-0" tabIndex={0} data-testid="drawerCloseButton">
			<CloseIcon className="pa3 f3 theme-grey" />
		</div>
	);

	return (
		<Drawer
			ariaLabel="Filter Search Results"
			onClose={onFacetDrawerClose}
			direction="right"
			className={isNewLookAndFeel ? `pa0 z-9999 ${drawer}` : 'pa0 z-4'}
			classContainerContent={isNewLookAndFeel ? 'flex flex-column h-100' : ''}
			customCloseButton={customCloseButton}>
			<FacetDrawerHeader
				useResults={useResults}
				clearFacets={clearFacets}
				onFacetDrawerClose={onFacetDrawerClose}
				resultCount={formattedCount}
				searchHeaderV2FlagStatus={searchHeaderV2FlagStatus}
				loading={loading}
				isNewLookAndFeel={isNewLookAndFeel}
				hasFilters={hasFilters}
			/>
			<div className={isNewLookAndFeel ? `flex-grow-1 pb2 overflow-y-auto ${facetDrawerList}` : 'relative'}>
				<FacetList
					listContainerStyle={listContainerStyle}
					useResults={useResults}
					useSearchFacetGroupResults={useSearchFacetGroupResults}
					hideDesktopHeader={true}
					scrollable={!isNewLookAndFeel}
					includeSortBy={isSortInsideFacetDrawer}
				/>
				{loading && !isNewLookAndFeel && (
					<div className="absolute top-0 w-100 h-100 bg-theme-white-o70 z-1">
						<PageLoading />
					</div>
				)}
			</div>
			{isNewLookAndFeel && (
				<div className="w-100 pb4 pt2 bg-theme-white bt b--theme-grey-light">
					<FacetFilterList
						useResults={useSearchResults}
						hideClearAll={true}
						className={`flex-nowrap overflow-x-auto overflow-y-hidden nowrap ph4 pv3 mb2 ${facetDrawerFilters}`}
					/>
					<div className={`ph4 center tc w-100 ${hasFilters ? '' : 'mt3'}`}>
						<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
							View {formattedCount} {pluralize('Result', Number(resultCount))}
						</StyledButton>
					</div>
				</div>
			)}
		</Drawer>
	);
};
