import { logError } from 'fergy-core-react-logging';
import { type Maybe, type ProductSortEnum, type ProductViewEnum } from '../../__generated__/graphql-client-types';
import { QUICKSHIP_FACET_GROUP_NAME, QUICKSHIP_FACET_VALUE, SORT_BY_MAP } from '../../constants/search';
import type { DropType, FacetGroupBase, FacetValueBase, ProductSearchResult } from '../../types/search.types';
import { doesWindowExist } from '../../utils/dom/dom.utils';
import { AnalyticsHelper } from '../analytics/analytics.helper';
import { TrackedEvent, TrackedEventCase } from '../analytics/event-types';
import {
	buildGTMProductClick,
	buildGTMFindingMethodSearchDrop,
	buildGTMFindingMethodCategoryDrop,
	buildGTMFindingMethodCollectionDrop,
	buildGTMFindingMethodNonstockDrop,
	buildGTMFindingMethodProductListDrop
} from '../analytics/gtm/event-builders';
import { type GTMEventWrapper, type GTMFindingMethodEvent } from '../analytics/gtm/gtm-types';
import { buildGTMFacetChange } from '../analytics/gtm/gtm-utils.helper';
import { formatProductDataForDatalayer } from '../datalayer-helper/plp-datalayer.helper';

export function generatePageViewSearchDataLayer(
	pageName: string,
	dropType: DropType,
	results: ProductSearchResult | undefined,
	page: number,
	pageSize: number,
	sortBy: ProductSortEnum,
	query?: string,
	searchSource?: string | null,
	viewType?: Maybe<ProductViewEnum>,
	nonstock = false
) {
	const searchTerm = dropType === 'category' ? getTrackingSearchTermFromDropType(dropType) : query;
	return {
		page: pageName,
		pageNameData: `${doesWindowExist() ? window.location.hostname : 'localhost'}:search::p${page}:r${pageSize}:g`,
		dropType: 'search',
		merchCategory: 'non-browse',
		searchTerm,
		correctedSearchTerm: results?.correctedSearchTerm,
		resultCount: results?.count,
		products: results?.products && results.products.length > 0 ? formatProductDataForDatalayer(results.products) : [],
		searchSource,
		productDropTracking: `s!search!c~!p${page}!r${pageSize}!v${viewType === 'LIST' ? 'list' : 'tile'}!sb${SORT_BY_MAP[sortBy]}`,
		nonstock
	};
}

export function trackSearchEvent(event: GTMEventWrapper | null) {
	if (!event) return void 0;
	return AnalyticsHelper.track(event).catch(logError);
}

export const createGTMEvent = (action: TrackedEvent, data?: Record<string, any>): GTMEventWrapper | null => {
	if (!data) return null;
	if (action === TrackedEvent.FACET_INTERACTION) {
		const { query, facet, group, applied } = data;
		return resolveGTMFacetChange(query, facet, group, applied);
	} else if (
		[TrackedEvent.MORE_FILTERS, TrackedEvent.LESS_FILTERS, TrackedEvent.EXPAND_FACET_GROUP, TrackedEvent.COLLAPSE_FACET_GROUP].includes(
			action
		)
	) {
		return { event: TrackedEvent.SHOW_MORE_FACETS, type: action, ...(data.group ? { groupName: data.group.name } : {}) };
	} else if (action === TrackedEvent.PRODUCT_CLICK) {
		const { product, dropType = 'other', cardIndex, variant } = data;
		const eventType = getProductClickEventCaseFromDropType(dropType);
		const trackingSearchTerm = getTrackingSearchTermFromDropType(dropType);
		return buildGTMProductClick(product, eventType, trackingSearchTerm, cardIndex, variant);
	}
	return null;
};

/**
 * Analytics tracking for a facet being applied or removed.
 */
export function resolveGTMFacetChange(query: string, facet: FacetValueBase, group: FacetGroupBase, applied: boolean): GTMEventWrapper {
	const isQuickShip = group.name.toLowerCase() === QUICKSHIP_FACET_GROUP_NAME.toLowerCase();
	const groupName = (isQuickShip ? QUICKSHIP_FACET_GROUP_NAME : group.name).toLowerCase();
	const value = (isQuickShip ? QUICKSHIP_FACET_VALUE : facet.value).toLowerCase();
	return buildGTMFacetChange(query, value, groupName, applied);
}

const DROPTYPE_TO_TRACKING_SEARCH_TERM: Record<DropType, string> = {
	search: 'search term results',
	category: 'category product drop',
	collection: 'collection results',
	'nonstock-search': 'nonstock results',
	'product-list': 'product list',
	other: 'other'
};

export function getTrackingSearchTermFromDropType(dropType: DropType): string {
	return DROPTYPE_TO_TRACKING_SEARCH_TERM[dropType];
}

const DROPTYPE_TO_PRODUCT_CLICK_EVENT_CASE: Record<DropType, TrackedEventCase> = {
	search: TrackedEventCase.SEARCH_PLP_PRODUCT_CLICK,
	category: TrackedEventCase.CATEGORY_PLP_PRODUCT_CLICK,
	collection: TrackedEventCase.COLLECTION_PLP_PRODUCT_CLICK,
	'nonstock-search': TrackedEventCase.NONSTOCK_PLP_PRODUCT_CLICK,
	'product-list': TrackedEventCase.PRODUCT_LIST_PRODUCT_CLICK,
	other: TrackedEventCase.OTHER_PRODUCT_CLICK
};

export function getProductClickEventCaseFromDropType(dropType: DropType): TrackedEventCase {
	return DROPTYPE_TO_PRODUCT_CLICK_EVENT_CASE[dropType];
}

export function getFindingMethodEventByDropType(dropType: DropType): GTMFindingMethodEvent | null {
	switch (dropType) {
		case 'search':
			return buildGTMFindingMethodSearchDrop();
		case 'category':
			return buildGTMFindingMethodCategoryDrop();
		case 'collection':
			return buildGTMFindingMethodCollectionDrop();
		case 'nonstock-search':
			return buildGTMFindingMethodNonstockDrop();
		case 'product-list':
			return buildGTMFindingMethodProductListDrop();
		default:
			return null;
	}
}
