import { useEffect, useState } from 'react';
import { LARGE_MEDIA_QUERY, MEDIUM_MEDIA_QUERY } from '../../constants/media-queries';
import { doesWindowExist, generateListener } from '../../utils/dom/dom.utils';

export type ClientOnlyBreakpoints = {
	isMedium: boolean;
	isLarge: boolean;
	isNotSmall: boolean;
};

export function useClientOnlyBreakpoints(): ClientOnlyBreakpoints {
	if (doesWindowExist()) {
		const [isMedium, setIsMedium] = useState(window.matchMedia(MEDIUM_MEDIA_QUERY).matches);
		const [isLarge, setIsLarge] = useState(window.matchMedia(LARGE_MEDIA_QUERY).matches);

		const mediumListener = generateListener(setIsMedium);
		const largeListener = generateListener(setIsLarge);

		useEffect(() => {
			const mediumMediaQueryList = window.matchMedia(MEDIUM_MEDIA_QUERY);
			const largeMediaQueryList = window.matchMedia(LARGE_MEDIA_QUERY);

			// initial setting
			setIsMedium(mediumMediaQueryList.matches);
			setIsLarge(largeMediaQueryList.matches);

			// listen for changes
			// JSDOM only implements addListener, not addEventListener
			mediumMediaQueryList.addListener(() => mediumListener);
			largeMediaQueryList.addListener(() => largeListener);

			// remove listeners on unmount
			return () => {
				// JSDOM only implements removeListener, not removeEventListener
				mediumMediaQueryList.removeListener(() => mediumListener);
				largeMediaQueryList.removeListener(() => largeListener);
			};
		}, [isMedium, isLarge, mediumListener, largeListener]);

		return {
			isMedium,
			isLarge,
			isNotSmall: isMedium || isLarge
		};
	} else {
		throw new Error('Breakpoints should not be used during SSR');
	}
}
